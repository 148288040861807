import React from 'react'

function Coinloader() {
    return(
        <>
        <div style={{display:'flex', justifyContent: 'center', marginTop:'4rem'}}>
        <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
</div>
</>
    )
}

function Textloader() {
    return(
        <>
        <div class="balls">
  <div></div>
  <div></div>
  <div></div>
</div>
</>
        )
    }

export {Coinloader,Textloader}